import { template as template_5e721743a00249c6ad1b91505a4992e3 } from "@ember/template-compiler";
const FKLabel = template_5e721743a00249c6ad1b91505a4992e3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
